<!-- Base Mantenimiento - Detalle -->

<template>
  <div class="base_MD">
    <component :is="finderName" :id="id" @onEvent="event_capture"></component>

    <!-- Slot con los controles de la Cabecera -->
    <!-- <slot name="ctrls_cab"></slot> -->

    <!-- Base Detalle -->
    <!-- <base_D
            ref="base_D"
            :id="id"
            :accion="accion"
            :edicion="edicion"
            :finder="finder"
            :sGrid="sGrid"
            :selected="selected"
            :btra="btra"
             @onEvent="event_capture"> -->

    <!-- Slot con los controles del Detalle -->
    <!-- <template v-slot:ctrls_detalle="{ schema }">
                    <slot name="ctrls_detalle" :schema="schema"></slot>
                </template>    

        </base_D> -->

    <!-- Ventana Linea Detalle -->
    <!-- <v-dialog
            class="modal_lineaDetalle"           
            v-model="modal"
            v-bind="ent_mtoModal">            
            
            <template>               
                <v-card>                    
                    <component :is="mtoName" :id_accion="id_accion" :schema="schema_detalle" @onEvent="event_capture"></component>
                </v-card>
            </template>
        </v-dialog>  -->
  </div>
</template>

<script>
import plugs from "@/common/general_plugs";
const base_D = () => plugs.groute("base_D.vue", "base");

export default {
  components: { base_D },
  props: {
    schema: { type: Object, default: null },
    id: { type: String, default: "" },
    accion: { type: String, default: "" },
    edicion: { type: Boolean, default: false },
    finder: { type: String, default: "" },
    mto: { type: String, default: "" }
  },

  data() {
    return {
      // variables de configuración
      ent_mtoModal: this.$entorno.mto.ventanaModal,

      // variables del componente
      schema_detalle: null,
      modal: false,
      id_accion: {}
    };
  },

  computed: {
    // importo el componente particular
    finderName() {
      return () => this.$groute(`${this.finder}.vue`, "comp");
    },
    mtoName() {
      return () => this.$groute(`${this.mto}.vue`, "comp");
    }
  },

  methods: {
    //
    event_capture(evt) {
      console.log("*** event_capture base_MD. Accion: " + evt.accion + " ***");

      switch (evt.accion) {
        case "cerrar_form":
          this.modal = false;
          break;

        case "0":
        case "2":
          console.log(evt);

          this.id_accion.id = evt.record ? evt.record.id : null;
          this.id_accion.accion = evt.accion;
          this.schema_detalle = this.$refs.base_D.schema;
          this.modal = true;
          break;
      }

      this.$emit("onEvent", evt);
    }
  },

  watch: {
    id() {
      this.$refs.base_D.load_detalle();
    }
  }
};
</script>
